const availableNetworks = ['scroll'] as const
type AvailableNetworks = (typeof availableNetworks)[number]

// RPC
type RPCs = Record<
    AvailableNetworks,
    {
        http: string
    }
>
const rpcs: RPCs = {
    scroll: {
        http: process.env.NEXT_PUBLIC_SCROLL_URL as string,
    },
}

// Addresses
type Addresses = Record<
    AvailableNetworks,
    {
        troopV0FactoryProxy: `0x${string}`
        tbtFactoryProxy: `0x${string}`
    }
>
const addresses: Addresses = {
    scroll: {
        troopV0FactoryProxy: '0xfd3ae43f41ce8AE32382C2ad01c7C382993bba72',
        tbtFactoryProxy: '0x26881E8C452928A889654e4a8BaFBf205dD87812',
    },
}

// Block explorers
type BlockExplorers = Record<
    AvailableNetworks,
    {
        explorer: string
    }
>
const blockExplorers: BlockExplorers = {
    scroll: { explorer: 'https://scrollscan.com' },
}

// Urls
type Urls = Record<
    AvailableNetworks,
    {
        url: string
        txUrl: string
    }
>
const safeUrl: Urls = {
    scroll: { url: 'https://safe.scroll.xyz', txUrl: 'https://transaction.safe.scroll.xyz' },
}

const config = {
    rpcs,
    addresses,
    blockExplorers,
    safeUrl,
} as const

export default config
