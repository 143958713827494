// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { deleteToken, getMessaging, getToken, onMessage } from 'firebase/messaging'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: 'AIzaSyAW2odExtO1eBDcuCUhw1KQdRvCeSYfa0E',
    authDomain: 'troops-397fa.firebaseapp.com',
    projectId: 'troops-397fa',
    storageBucket: 'troops-397fa.appspot.com',
    messagingSenderId: '257321982384',
    appId: '1:257321982384:web:7a9fda454ab6f56e331e75',
    measurementId: 'G-KX68H760B5',
}
export const firebase = initializeApp(firebaseConfig)

if (typeof window !== 'undefined') {
    onMessage(getMessaging(firebase), function ({ data }) {
        // TODO: Display in-app popups for foreground push notifications
        console.log(`[foreground-messaging]:`, data)
    })
}

export const initializeFirebase = () => {
    initializeApp(firebaseConfig)
}

export const removeNotificationToken = async () => {
    const messaging = getMessaging(firebase)
    await deleteToken(messaging)
}

export const askForPermissionToReceiveNotifications = async () => {
    try {
        if (typeof window !== 'undefined' && 'Notification' in window) {
            console.log('Requesting permission...')
            const permission = await Notification.requestPermission()

            if (permission === 'granted') {
                console.log('Notification permission granted.')
                const messaging = getMessaging(firebase)

                try {
                    const token = await getToken(messaging)
                    if (token) {
                        return token
                    } else {
                        return undefined
                    }
                } catch (tokenError) {
                    console.error('Error getting token:', tokenError)
                }
            } else {
                console.log('Notification permission not granted.')
            }
        } else {
            console.log('Notifications not supported in this browser.')
        }
    } catch (error) {
        console.error('Error requesting permission:', error)
    }
}

export const storage = getStorage(firebase)
