import { trpc } from '@/src/lib/trpc'
import {
    Button,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useMediaQuery,
    useToast,
} from '@chakra-ui/react'
import { useSIWE } from 'connectkit'
import { useAccount, useDisconnect } from 'wagmi'

import useLocalStorage from '@/src/hooks/useLocalStorage'
import { askForPermissionToReceiveNotifications } from '@/src/utils/firebase'
import useMountedState from '@/src/hooks/useMountedState'

export default function NotificationApproval() {
    const { address } = useAccount()
    const { isSignedIn }: { isSignedIn: boolean } = useSIWE()
    const isMounted = useMountedState()
    const [isLargerThanTablet, isDisplayingInBrowser] = useMediaQuery([
        '(min-width: 768px)',
        '(display-mode: browser)',
    ])

    const [askedNotifPermission, setAskedNotifPermission] = useLocalStorage<boolean | undefined>(
        'askedNotifPermission',
        undefined
    )
    const [notificationToken, setNotificationToken] = useLocalStorage<string | undefined>(
        'notificationToken',
        undefined
    )

    const askNotification =
        isMounted() &&
        (!isDisplayingInBrowser || (isDisplayingInBrowser && isLargerThanTablet)) &&
        !askedNotifPermission

    const { data: user, status: fetchUserStatus } = trpc.users.getByWallet.useQuery(
        address?.toLowerCase() as Lowercase<`0x${string}`>,
        {
            enabled: Boolean(address),
        }
    )

    const isConnected = Boolean(address && isSignedIn)
    const signedUp = fetchUserStatus === 'success' && user
    const toast = useToast()

    const { mutate: addNotificationToken, isLoading } = trpc.notificationToken.create.useMutation()

    const saveNotificationToken = async () => {
        try {
            const token = await askForPermissionToReceiveNotifications()
            if (typeof Notification !== 'undefined' && Notification.permission === 'granted') {
                setNotificationToken(token)
                addNotificationToken({
                    firebase_token: token as string,
                })
            }
        } catch {
            toast({
                status: 'error',
                description: 'Something went wrong with activating notifications',
                isClosable: true,
            })
        } finally {
            setAskedNotifPermission(true)
        }
    }

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={Boolean(isConnected && signedUp && askNotification)}
            onClose={() => {}}
            size={['full', 'full', 'lg']}
        >
            <ModalOverlay />
            <ModalContent py={4}>
                <Image
                    height={'30%'}
                    width={'80%'}
                    alignSelf={'center'}
                    src="/ask-notification.svg"
                    alt="Metamask wallet logo"
                    marginBottom={8}
                />

                <ModalHeader textAlign={'center'}>Notifications</ModalHeader>
                <ModalBody
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'space-evenly'}
                >
                    <Text mb={4}>
                        Our app uses notifications to keep you up to date with your Troop!
                    </Text>

                    <Button
                        backgroundColor={'black'}
                        rounded={'full'}
                        isLoading={isLoading}
                        onClick={saveNotificationToken}
                        color={'white'}
                        width={'80%'}
                        padding={6}
                        disabled={isLoading}
                        mb={4}
                    >
                        Allow notifications
                    </Button>
                    <Text
                        style={{ cursor: 'pointer' }}
                        onClick={() => setAskedNotifPermission(true)}
                    >
                        Maybe later
                    </Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
