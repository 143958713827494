import type { AppProps } from 'next/app'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { scroll } from '@/src/lib/customNetworks'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'
import { ChakraProvider, useMediaQuery } from '@chakra-ui/react'
import { ConnectKitProvider, getDefaultConfig, SIWESession } from 'connectkit'

import { trpc } from '../lib/trpc'
import { siweClient } from '../lib/siweClient'
import theme from '../theme'
import UserSetup from '../components/UserSetup/UserSetup'
import { NoSSR } from '@/src/components/NoSSR'
import config from '@/src/config'
import Fonts from '@/styles/fonts'
import { initializeFirebase } from '../utils/firebase'
import NotificationApproval from '../components/NotificationApproval'
import InstallPWADrawer from '../components/InstallPWADrawer'

const appChainId = parseInt(process.env.NEXT_PUBLIC_APP_CHAIN_ID ?? '534352')

const wagmiConfig = createConfig(
    getDefaultConfig({
        alchemyId: process.env.NEXT_PUBLIC_ALCHEMY_KEY!,
        walletConnectProjectId: process.env.NEXT_PUBLIC_WC_PROJECT_ID!,
        appName: 'Troop 🫡',
        appDescription: 'Troop is the open-source community stack for onchain communities',
        appUrl: 'https://',
        chains: [scroll],
    })
)

configureChains(
    [scroll],
    [
        jsonRpcProvider({
            rpc: (chain) => {
                if (chain.id !== appChainId) {
                    throw new Error(`Unexpected chain ${chain.id} (${chain.name})`)
                }
                return {
                    http: config.rpcs.scroll.http,
                }
            },
        }),
    ]
)

initializeFirebase()

function App(props: AppProps) {
    const [isMaxTablet, isDisplayingInBrowser] = useMediaQuery([
        '(max-width: 768px)',
        '(display-mode: browser)',
    ])
    const showInstallPWADrawer = isMaxTablet && isDisplayingInBrowser

    return (
        <ChakraProvider theme={theme}>
            <Fonts />
            <WagmiConfig config={wagmiConfig}>
                <siweClient.Provider
                    // Optional parameters
                    enabled={true} // defaults true
                    nonceRefetchInterval={300000} // in milliseconds, defaults to 5 minutes
                    sessionRefetchInterval={300000} // in milliseconds, defaults to 5 minutes
                    signOutOnDisconnect={true} // defaults true
                    signOutOnAccountChange={true} // defaults true
                    signOutOnNetworkChange={true} // defaults true
                    onSignIn={(session?: SIWESession) => {}}
                    onSignOut={() => {}}
                >
                    <ConnectKitProvider theme="midnight">
                        <NoSSR>
                            {showInstallPWADrawer ? (
                                <InstallPWADrawer />
                            ) : (
                                <>
                                    <props.Component {...props.pageProps} />
                                    <NotificationApproval />
                                </>
                            )}
                        </NoSSR>
                    </ConnectKitProvider>
                </siweClient.Provider>
            </WagmiConfig>
        </ChakraProvider>
    )
}

export default trpc.withTRPC(App)
